import React from 'react';
import '../style/sidebar.css';

interface settings { user: User | boolean; logout: () => void  }
const Settings: React.FC<settings> = ({ user, logout }) => {
  return (
    <div className="settings-content-container" id="settings">
        <div className="settings-main-content">
            <div className="settings-profile-section">
                <div className="settings-profile-header">
                    <img src={user && typeof user === "object" ? user.pfp : "G"} alt=''/>
                    <div className="settings-profile-info">
                        <h2>{user && typeof user === "object" ? user.displayname : "Guest"}</h2>
                        <p>Member since: 2022</p>
                    </div>
                </div>
                <h3 className="settings-section-title">Account info</h3>
                <div className="settings-profile-item">
                    <div>
                        <strong>Display name:</strong> {user && typeof user === "object" ? user.displayname : "Guest"}
                    </div>
                    <button>Edit</button>
                </div>
                <div className="settings-profile-item">
                    <div>
                        <strong>E-mail:</strong> {user && typeof user === "object" ? user.email : "Guest"}
                    </div>
                    <button>Edit</button>
                </div>
                <div className="settings-profile-item">
                    <div>
                        <strong>Telefonszám:</strong> *****9359 
                    </div>
                    <div className="buton-container">
                        <button>Show</button>
                        <button className="settings-danger">Delete</button>
                    </div>
                </div>
                <h3 className="settings-section-title">Password and Authentication</h3>
                <div className="settings-profile-item">
                    <button>Change password</button>
                </div>
                <div className="settings-profile-item">
                    <button onClick={logout}>Log out</button>
                    <button>Delete account</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Settings;