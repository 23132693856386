import React from 'react';
import { Users, Gitlab, Target, Star, Zap, Shield, Music, Book, Search, Lock } from 'react-feather';
import '../style/discover.css';

interface ServerCardProps {
  name: string;
  description: string;
  members: number;
  online: number;
  image: string;
  icon: React.ReactNode;
  featured?: boolean;
  iconColor: string;
}

const ServerCard: React.FC<ServerCardProps> = ({ name, description, members, online, image, icon, featured, iconColor }) => (
  <div className="group relative bg-gradient-to-br from-slate-800 to-slate-900 rounded-xl overflow-hidden hover:shadow-xl hover:shadow-slate-700/20 transition-all duration-300 cursor-pointer">
    {featured && (
      <div className="absolute top-3 right-3 z-10">
        <div className="bg-yellow-500/90 backdrop-blur-sm text-xs px-2 py-1 rounded-full flex items-center gap-1">
          <Gitlab size={12} className="text-yellow-100" />
          <span className="text-yellow-100 font-medium">Kiemelt</span>
        </div>
      </div>
    )}
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-t from-slate-900/80 to-transparent z-[1]"></div>
      <img 
        src={image} 
        alt={name}
        className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-500"
      />
      <div className="absolute bottom-4 left-4 z-10 flex items-center gap-3">
        <div className="relative">
          <div className={`w-14 h-14 rounded-2xl ${iconColor} flex items-center justify-center shadow-lg`}>
            {icon}
          </div>
          <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full border-2 border-slate-900"></div>
        </div>
      </div>
    </div>
    <div className="p-4">
      <div className="flex justify-between items-start mb-2">
        <h3 className="font-bold text-white text-lg">{name}</h3>
      </div>
      <p className="text-slate-400 text-sm mb-4 line-clamp-2">{description}</p>
      <div className="flex items-center gap-4 text-slate-400 text-sm">
        <div className="flex items-center gap-1.5">
          <div className="w-2 h-2 rounded-full bg-green-500"></div>
          <span>{online.toLocaleString()} Online</span>
        </div>
        <div className="flex items-center gap-1.5">
          <Users size={16} />
          <span>{members.toLocaleString()} Tag</span>
        </div>
      </div>
    </div>
  </div>
);

interface Server {
  name: string;
  description: string;
  members: number;
  online: number;
  image: string;
  featured?: boolean;
  icon: React.ReactNode;
  iconColor: string;
}

const Discover: React.FC = () => {
  const servers: Server[] = [
    {
      name: "Genshin Impact HU",
      description: "Üdvözlünk Teyvat-ban, Utazó! Csatlakozz a legnagyobb magyar Genshin Impact közösséghez, ahol közösen fedezhetjük fel a játék világát.",
      members: 1964958,
      online: 476236,
      image: "/api/placeholder/800/400",
      featured: true,
      icon: <Shield size={28} className="text-purple-100" />,
      iconColor: "bg-gradient-to-br from-purple-500 to-purple-700"
    },
    {
      name: "Gaming Központ",
      description: "A legnagyobb magyar gaming közösség! Minden ami játék, e-sport és gaming kultúra egy helyen.",
      members: 856432,
      online: 234567,
      image: "/api/placeholder/800/400",
      icon: <Target size={28} className="text-blue-100" />,
      iconColor: "bg-gradient-to-br from-blue-500 to-blue-700"
    },
    {
      name: "Anime & Manga",
      description: "Anime rajongók közössége! Beszélgetések, események, és közös nézések egy baráti közösségben.",
      members: 654321,
      online: 123456,
      image: "/api/placeholder/800/400",
      icon: <Star size={28} className="text-pink-100" />,
      iconColor: "bg-gradient-to-br from-pink-500 to-pink-700"
    },
    {
      name: "Tech Hub",
      description: "Technológia, programozás és innovációk kedvelőinek. Segítünk egymásnak fejlődni!",
      members: 543210,
      online: 98765,
      image: "/api/placeholder/800/400",
      icon: <Zap size={28} className="text-orange-100" />,
      iconColor: "bg-gradient-to-br from-orange-500 to-orange-700"
    }
  ];

  return (
    <div className="pt-24 p-8">
      <div className="max-w-[1800px] mx-auto">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">FIND YOUR COMMUNITY ON DISCORD</h1>
          <p className="text-slate-400 text-lg">From gaming, to music, to learning, there's a place for you.</p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {servers.map((server, index) => (
                <ServerCard key={index} {...server} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Discover;